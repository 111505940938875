//mui

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
function Consent({
  title,
  description,
  open,
  setOpen,
  handleSuccess,
  ...props
}) {
  const handleClose = () => {
    setOpen((prev) => !prev);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: "16px" }}>
        <Button onClick={handleClose}>{props.rejectButtonTitle}</Button>
        <Button
          onClick={handleSuccess}
          autoFocus
          disableElevation
          variant="contained"
          sx={{
            background: "#2F7EC7",
            ":hover": {
              background: "#286eaf",
            },
          }}
        >
          {props.acceptButtonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Consent;

Consent.defaultProps = {
  title: "Consent Title",
  description: "Your consent description",
  open: false,
  setOpen: () => {},
  handleSuccess: () => {},
};
