//mui
import Typography from "@mui/material/Typography";
//libs
import PropTypes from "prop-types";

function TableTitle({ title, ...props }) {
  return (
    <Typography
      sx={{
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "22px",
        p: "24px",
        pb: "0",
        textTransform: "uppercase",
        ...props.sx,
      }}
    >
      {title}
    </Typography>
  );
}
TableTitle.defaultProps = {
  title: "Your Table Title",
};
TableTitle.propTypes = {
  title: PropTypes.string,
};
export default TableTitle;
