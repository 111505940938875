// libraries
import PropTypes from "prop-types";
// material UI components
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
//assets
import CreditSvg from "../assets/dashboard/credits_rectangle.svg";

const CreditsIcon = (props) => {
  return (
    <Icon sx={{ width: "28px", height: "28px", ...props.sx }}>
      <img src={CreditSvg} alt="credits_icon" />
    </Icon>
  );
};
export default function Credits({ usage }) {
  // const theme = useTheme();

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#2f7ec7",
          boxShadow: `inset 0 0 0px 7px #d5dff0`,
          borderRadius: "100%",
        }}
        size={92}
        thickness={3.5}
        value={usage}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CreditsIcon />
      </Box>
    </Box>
  );
}

Credits.propTypes = {
  usage: PropTypes.number.isRequired,
};
