// libraries
import { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

// material UI styles
import { useTheme, makeStyles } from "@mui/styles";

// material UI components
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

// material UI icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";

// utils
import urls from "../utils/urls.json";
import { globalContext } from "../context/globalContext";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: "#2f7ec7",
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: "5px",
    marginRight: "10px",
  },
}));

export default function DropDown({ firstName, lastName, dispatch, email }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const theme = useTheme();
  const classes = useStyles();
  const globalCtx = useContext(globalContext);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleLogout = async () => {
    dispatch({
      type: "signout",
      error: null,
      globalCtx: globalCtx,
      notification: { enqueueSnackbar, closeSnackbar },
    });
  };

  return (
    <>
      <Button
        ref={anchorRef}
        id="account-menu"
        aria-controls={open ? "account-options" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        size="small"
        variant="text"
      >
        <Avatar alt={firstName} className={classes.avatar}>
          <PersonIcon fontSize="small" />
        </Avatar>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.primary.main,
            marginRight: "3px",
            textTransform: "capitalize",
          }}
          noWrap
        >
          {firstName}
        </Typography>
        <ExpandMoreIcon
          fontSize="small"
          style={{ color: "#939393", marginTop: "2px" }}
        />
      </Button>
      <Stack direction="row" spacing={2}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    onKeyDown={handleListKeyDown}
                    sx={{ width: "240px" }}
                  >
                    <MenuItem>
                      <Avatar alt={firstName} className={classes.avatar}>
                        <PersonIcon fontSize="small" />
                      </Avatar>
                      <ListItemText>
                        <Typography
                          variant="body2"
                          sx={{
                            color: theme.palette.primary.main,
                            marginRight: "3px",
                            textTransform: "capitalize",
                            fontSize: "12px",
                          }}
                          noWrap
                        >
                          {firstName} {lastName}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#666666",
                            fontSize: "12px",
                          }}
                          noWrap
                        >
                          {email}
                        </Typography>
                      </ListItemText>
                    </MenuItem>

                    <Divider style={{ margin: 0 }} />

                    <MenuItem
                      component={Link}
                      to={urls.profile}
                      onClick={handleClose}
                      sx={{ py: "10px" }}
                    >
                      <ListItemText>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#333333",
                            fontSize: "14px",
                          }}
                          noWrap
                        >
                          My Profile
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                    <Divider style={{ margin: 0 }} />
                    <MenuItem onClick={handleLogout} sx={{ py: "10px" }}>
                      <ListItemText>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#333333",
                            fontSize: "14px",
                          }}
                          noWrap
                        >
                          Log Out
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Stack>
    </>
  );
}
