import AuthHOC from "../hoc/AuthHOC";
import { Paper, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import SuccessLogo from "../assets/Vector.svg";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import urls from "../utils/urls.json";
import { Link } from "react-router-dom";

const breadcrumbs = [
  <Typography key="1"></Typography>,
  <Link key="2" to={urls.credits}>
    Back to Credits
  </Link>,
];

function Success({ dispatch }) {
  return (
    <Container
      maxWidth="md"
      style={{
        padding: "120px 0px 0px 0px",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Stack direction="column" spacing={3}>
        <Stack justifyContent="center" alignItems="center">
          <Paper
            elevation={0}
            style={{
              width: "100%",
              padding: "80px",

              boxShadow: "0px 5px 29px rgba(112, 112, 112, 0.06)",
              borderRadius: "16px",
            }}
          >
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              alignItems="center"
              spacing={3}
            >
              <img src={SuccessLogo} alt="done" />

              <Typography
                sx={{
                  fontSize: "28px",
                  fontWeight: "700",
                  color: "#0F0F0F",
                }}
              >
                Payment was completed successfully{" "}
              </Typography>
            </Stack>
          </Paper>
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Breadcrumbs
            separator={<NavigateBeforeIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      </Stack>
    </Container>
  );
}

export default AuthHOC(Success);
