//react
import { createContext, useEffect, useMemo, useContext, useRef } from "react";
//3rd party
import axios from "axios";
//utils and helper
import { HandleAuthToken, axiosConfig, DEBUG } from "../utils/helpers";
import { globalContext } from "./globalContext";

// custom modules
import Loading from "../components/helpers/loading";
import { useSnackbar } from "notistack";

export const AuthContext = createContext();

export async function getNewToken(refresh) {
  const params = {
    method: "POST",
    uri: "/auth/token/refresh/",
    data: DEBUG ? { refresh } : {},
  };
  let config = axiosConfig({ ...params });
  const authHandler = new HandleAuthToken();
  if (DEBUG) {
    config.headers["Authorization"] = `Bearer ${authHandler.retrieveToken()}`;
  } else {
    config["withCredentials"] = true;
  }
  try {
    const response = await axios({ ...config });
    return {
      accessToken: {
        token: response.data.access,
        expiration: response.data.access_token_expiration,
      },
      error: null,
    };
  } catch (error) {
    return { accessToken: null, error };
  }
}

export async function checkToken(token) {
  const params = {
    method: "POST",
    uri: "/auth/token/verify/",
    data: DEBUG ? { token } : {},
  };

  let config = axiosConfig({ ...params });
  const authHandler = new HandleAuthToken();
  if (DEBUG) {
    config.headers["Authorization"] = `Bearer ${authHandler.retrieveToken()}`;
  } else {
    config["withCredentials"] = true;
  }
  try {
    const response = await axios({ ...config });
    return {
      detail: response.data.detail,
      error: null,
    };
  } catch (error) {
    return { detail: null, error };
  }
}

async function getUser() {
  const params = {
    method: "GET",
    uri: "/auth/user/",
  };
  let config = axiosConfig({ ...params });
  const authHandler = new HandleAuthToken();
  try {
    //manually adding token header
    if (DEBUG) {
      config.headers["Authorization"] = `Bearer ${authHandler.retrieveToken()}`;
    } else {
      config["withCredentials"] = true;
    }
    const response = await axios({ ...config });
    return {
      user: response.data,
      error: null,
    };
  } catch (error) {
    return {
      user: null,
      error: error,
    };
  }
}

export function AuthProvider({ children }) {
  const globalCtx = useRef();
  globalCtx.current = useContext(globalContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { state, dispatch } = globalCtx.current;
  const authToken = useMemo(() => new HandleAuthToken(), []);
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  //this condition is for if someone tried to login with the credentials of different user.
  if (params.tokenExp && !authToken.retrieveTokenExp())
    authToken.addTokenExp(params.tokenExp);

  if (params.refreshTokenExp && !authToken.retrieveRefreshTokenExp())
    authToken.addRefreshTokenExp(params.refreshTokenExp);

  //for performing the auto logout based on exp time sent by the backend
  // useEffect(() => {
  //   const performAutoLogout = () => dispatch({ type: "signout", error: null });
  //   if (state.authenticated) {
  //     if (!Boolean(state.autoLogoutIntervalId)) {
  //       const rTokenExp = authToken.retrieveRefreshTokenExp();
  //       const expTimeInMs = new Date(rTokenExp).getTime() - Date.now();
  //       const autoLogoutIntervalId = setTimeout(performAutoLogout, expTimeInMs);
  //       dispatch({ type: "auto_logout_interval_id", autoLogoutIntervalId });

  //       return () => clearTimeout(state.autoLogoutIntervalId);
  //     }
  //   }
  // }, [authToken, state.autoLogoutIntervalId, state.authenticated]);

  useEffect(() => {
    (async () => {
      const token = authToken.retrieveToken();
      const rToken = authToken.retrieveRefreshToken();
      const tokenExp = authToken.retrieveTokenExp();
      const rTokenExp = authToken.retrieveRefreshTokenExp();

      // if any of these values are missing logout the user
      if (!tokenExp || !rTokenExp) {
        return dispatch({
          type: "app_loaded",
          error: null,
          user: {},
          authenticated: false,
        });
      }
      // check if token has expired (get new token)
      // if (new Date() > new Date(tokenExp)) {
      //   // check if refresh token is present
      //   if (new Date() <= new Date(rTokenExp)) {
      //     const { accessToken, error } = await getNewToken(rToken);
      //     if (!Boolean(error))
      //       dispatch({ type: "update_token", payload: accessToken });
      //     else {
      //       if (error.response?.status === 401) {
      //         dispatch({ type: "signout", error: null });
      //       }
      //       alert("You have been signed out! Please login again");
      //       return;
      //     }
      //   } else {
      //     alert("Your session has expired! Please login again");
      //     return dispatch({ type: "signout", error: null });
      //   }
      // }

      //If tokenExp if found in localstorage.
      const { error } = await checkToken(token);
      //getting user and setting it to the state
      if (!Boolean(error)) {
        const { user, error } = await getUser();
        if (!Boolean(error)) {
          dispatch({
            type: "app_loaded",
            user,
            authenticated: true,
            error: null,
          });
        } else {
          dispatch({
            type: "signout",
            error: null,
            globalCtx: globalCtx.current,
            notification: { enqueueSnackbar, closeSnackbar },
          });
        }
        //if error then get new token
      } else {
        // TODO: Generate new Token by checking the response object
        const { accessToken, error } = await getNewToken(rToken);
        if (!error) {
          authToken.addToken(accessToken.token);
          const { user, user_error } = await getUser();
          if (!user_error) {
            dispatch({
              type: "app_loaded",
              user,
              authenticated: true,
              error: null,
            });
          } else {
            dispatch({
              type: "signout",
              error: null,
              globalCtx: globalCtx.current,
              notification: { enqueueSnackbar, closeSnackbar },
            });
          }
        } else {
          dispatch({
            type: "signout",
            error: null,
            globalCtx: globalCtx.current,
            notification: { enqueueSnackbar, closeSnackbar },
          });
        }
      }
    })();
  }, [authToken, dispatch, enqueueSnackbar, closeSnackbar]);

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {state.loading ? <Loading /> : children}
    </AuthContext.Provider>
  );
}
